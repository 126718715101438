import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import API from "../../services/axios";
import iPhoneRadio from "../../photo/iPhoneRadio.png";
import iPhoneCheckbox from "../../photo/iPhoneCheckbox.png";
import AddIcon from "@mui/icons-material/Add";
import Question from "./Question";
import Loading from "./Loading";
import AspectRatio from "@mui/joy/AspectRatio";
import { CSSTransition } from "react-transition-group";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import DoneIcon from "@mui/icons-material/Done";

import {
  Input,
  Textarea,
  Sheet,
  Typography,
  Modal,
  Button,
  Box,
  Select,
  Option,
} from "@mui/joy";

const industries = [
  { label: "🩻 Digital Health", value: "digitalHealth" },
  { label: "🛒 eCommerce", value: "eCommerce" },
  { label: "🤑 Fintech", value: "fintech" },
  { label: "🎮 Gaming", value: "gaming" },
  { label: "🎽 Lifestyle", value: "lifestyle" },
  { label: "📰 Media", value: "media" },
  { label: "🗄️ Productivity", value: "productivity" },
  { label: "💬 Social media", value: "socialMedia" },
  { label: "⚙️ Other", value: "other" },
];

const Questionnaire = ({
  open,
  setOpen,
  questionsList,
  setQuestionList,
  setEdit,
  surveyUuid,
  AIQuota,
}) => {
  const [title, setTitle] = React.useState(
    surveyUuid ? questionsList.find((e) => e.uuid === surveyUuid).title : ""
  );
  const [description, setDescription] = React.useState(
    surveyUuid
      ? questionsList.find((e) => e.uuid === surveyUuid).description
      : ""
  );
  const [questions, setQuestions] = React.useState(
    surveyUuid ? questionsList.find((e) => e.uuid === surveyUuid).questions : []
  );
  const [generatedQuestions, setGeneratedQuestions] = React.useState(
    surveyUuid
      ? questionsList.find((e) => e.uuid === surveyUuid).generatedQuestions
      : []
  );
  const [currentQuestion, setCurrentQuestion] = React.useState(-1);
  const [questionNumber, setQuestionNumber] = React.useState(1);
  const [showFront, setShowFront] = React.useState(true);
  const [typePhoto, setTypePhoto] = React.useState("radio");
  const [error, setError] = React.useState(null);
  const [isQuestionComplete, setIsQuestionComplete] = React.useState(false);
  const [nextClickedAndNotComplete, setNextClickedAndNotComplete] =
    React.useState(false);
  const [selectedIndustry, setSelectedIndustry] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleTitleChange = (e) => {
    const newValue = e.target.value;
    setTitle(newValue);
  };

  const handleDescriptionChange = (e) => {
    const newValue = e.target.value;
    setDescription(newValue);
  };

  const handleNextQuestion = (e) => {
    if (currentQuestion === -1 && title !== "" && description !== "") {
      setCurrentQuestion(currentQuestion + 1);
      setNextClickedAndNotComplete(false);
      return;
    }

    if (!isQuestionComplete) {
      setNextClickedAndNotComplete(true);
      return;
    }

    setCurrentQuestion(currentQuestion + 1);
    setNextClickedAndNotComplete(false);
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion > -1) setCurrentQuestion(currentQuestion - 1);
  };

  const addQuestion = () => {
    if (
      isQuestionComplete ||
      (title !== "" && description !== "" && questions.length === 0)
    ) {
      const newQuestion = {
        uuid: uuidv4(),
        id: questions.length + 1,
      };
      setQuestions([...questions, newQuestion]);
      setQuestionNumber(questionNumber + 1);
      setCurrentQuestion(currentQuestion + 1);
      setNextClickedAndNotComplete(false);
    } else {
      setNextClickedAndNotComplete(true);
    }
  };

  const generateQuestions = async (e) => {
    e.preventDefault();
    if (title === "" || description === "" || selectedIndustry === "") {
      setNextClickedAndNotComplete(true);
      return;
    }

    if (generatedQuestions.length === 10) {
      // In case user delete all generated Questions, we retrieve it
      let generatedData = [...generatedQuestions];
      generatedData = generatedData.slice(0, 5).map((question, index) => ({
        ...question,
        id: questions.length + index + 1,
        passed: true,
      }));
      setQuestions([...questions, ...generatedData]);
      setCurrentQuestion(currentQuestion + 1);
      setNextClickedAndNotComplete(false);
      return;
    }

    const param =
      selectedIndustry === "other" ? "online service" : selectedIndustry;
    setLoading(true);
    const data = await API.generateAIQuestions({ param });
    await API.setAIQuota();

    let organizedData = data.questions.map((e, index) => {
      return {
        title: e.question.trim(),
        type: "radio",
        choices: e.possible_anwers.map((option) => option.trim()),
        passed: index < 5 ? true : false,
        uuid: uuidv4(),
      };
    });

    setGeneratedQuestions(organizedData);
    organizedData = organizedData.slice(0, 5).map((question, index) => ({
      ...question,
      id: questions.length + index + 1,
    }));

    setQuestions([...questions, ...organizedData]);
    setCurrentQuestion(currentQuestion + 1);
    setNextClickedAndNotComplete(false);
    setLoading(false);
  };

  const handleIndustryChange = (e, value) => {
    e.preventDefault();
    setSelectedIndustry(value);
  };

  const handleDone = async () => {
    const isValid = checkInputs(questions);

    if (!isValid) return;

    const updatedSurvey = {
      questions: [...questions],
      title,
      description,
      generatedQuestions,
      uuid: surveyUuid || uuidv4(),
    };

    setQuestionList((prevList) =>
      surveyUuid
        ? prevList.map((survey) =>
            survey.uuid === surveyUuid
              ? { ...survey, ...updatedSurvey }
              : survey
          )
        : [...prevList, updatedSurvey]
    );

    const surveyApiMethod = surveyUuid ? API.updateSurvey : API.createSurvey;

    await surveyApiMethod(updatedSurvey);

    setOpen(false);
    setEdit(null);
  };

  const checkInputs = (quest) => {
    if (quest.length === 0) {
      // Il faut aujouter une question
      setError("Il faut au moins ajouter une question.");
      return false;
    }

    if (title === "" || description === "") {
      // Il faut ajouter un titre et une description
      setError("Il faut ajouter un titre et une description.");
      return false;
    }

    for (let i = 0; i < quest.length; i++) {
      if (!quest[i].title || quest[i].title === "") {
        // Il faut un titre à votre question
        setError("Il manque un titre à l'une de vos questions.");
        return false;
      } else if (!quest[i].type || quest[i].type === "") {
        // Il faut un type à votre question
        setError("Il manque un type à une de vos questions.");
        return false;
      } else if (
        (quest[i].type === "checkbox" || quest[i].type === "radio") &&
        quest[i].choices.length === 0
      ) {
        // Il faut introduire au moins un choix
        setError("Il faut au minimum un choix.");
        return false;
      }
    }

    setError(null);
    return true;
  };

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => {
          setOpen(false);
          setEdit(null);
        }}
        sx={{
          zIndex: 1000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "stretch",
            justifyContent: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              flex: "1",
              width: "500px",
              maxHeight: "600px",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: currentQuestion === -1 ? "10px" : "0px",
              borderTopRightRadius: currentQuestion === -1 ? "10px" : "0px",
              p: 3,
              boxShadow: "lg",
              overflow: "auto",
              border: "0.5px solid #B9B9B9",
            }}
          >
            <div
              className="flippable-card-container"
              style={{ perspective: "1000px" }}
            >
              <CSSTransition in={showFront} timeout={400} classNames={"flip"}>
                {currentQuestion === -1 || questions.length === 0 ? (
                  <Box
                    sx={{
                      transform: showFront
                        ? "rotateY(0deg)"
                        : "rotateY(180deg)",
                    }}
                  >
                    <Typography
                      component="h2"
                      id="modal-title"
                      level="h4"
                      textColor="inherit"
                      fontWeight="lg"
                      mb={1}
                    >
                      Create your own onboarding flow.
                    </Typography>
                    <Typography sx={{ fontSize: "18px" }}>Title :</Typography>
                    <Input
                      placeholder="Title of the survey"
                      required
                      onChange={handleTitleChange}
                      value={title}
                      sx={{
                        mb: 1,
                        border: !nextClickedAndNotComplete
                          ? ""
                          : nextClickedAndNotComplete && title === ""
                          ? "2px solid red"
                          : "2px solid green",
                        ...(nextClickedAndNotComplete && {
                          "--Input-focusedHighlight": "transparent",
                        }),
                      }}
                    />
                    <Typography sx={{ fontSize: "18px" }}>
                      Description :
                    </Typography>
                    <Textarea
                      minRows={3}
                      required
                      onChange={handleDescriptionChange}
                      value={description}
                      sx={{
                        mb: 1,
                        border: !nextClickedAndNotComplete
                          ? ""
                          : nextClickedAndNotComplete && description === ""
                          ? "2px solid red"
                          : "2px solid green",
                        ...(nextClickedAndNotComplete && {
                          "--Textarea-focusedHighlight": "transparent",
                        }),
                      }}
                    />
                    {error && <Typography color="danger">{error}</Typography>}
                    {questions.length === 0 && AIQuota < 10 && (
                      <Box>
                        <Typography sx={{ fontSize: "18px" }}>
                          Industry :
                        </Typography>
                        <Select
                          placeholder="Select an Industry"
                          onChange={handleIndustryChange}
                          sx={{
                            mb: 1,
                            border: !nextClickedAndNotComplete
                              ? ""
                              : nextClickedAndNotComplete &&
                                selectedIndustry === ""
                              ? "2px solid red"
                              : "2px solid green",
                            ...(nextClickedAndNotComplete && {
                              "--Input-focusedHighlight": "transparent",
                            }),
                          }}
                        >
                          {industries.map((e) => (
                            <Option value={e.value} key={e.value}>
                              {e.label}
                            </Option>
                          ))}
                        </Select>
                        <Button
                          disabled={loading}
                          fullWidth
                          variant="solid"
                          color="#E4BB69"
                          onClick={generateQuestions}
                          sx={{
                            mb: 1,
                            mt: 1,
                            height: "60px",
                            backgroundImage:
                              "linear-gradient(to bottom, #EFD2B8, #FFFFBC)",
                              display: "flex",
                              justifyContent: "flex",
                              alignItems: "center",
                          }}
                          startDecorator={loading ? <></> : <AutoFixHighIcon />}
                        >
                          {loading ? <Loading /> : "Generate with AI"}
                        </Button>
                      </Box>
                    )}
                    <Button
                      disabled={loading}
                      //loading={loading}
                      fullWidth={true}
                      variant="solid"
                      color="neutral"
                      startDecorator={
                        questions.length - 1 === currentQuestion ? (
                          <AddIcon />
                        ) : (
                          <></>
                        )
                      }
                      onClick={
                        questions.length - 1 === currentQuestion
                          ? addQuestion
                          : handleNextQuestion
                      }
                      sx={{ mb: 1 }}
                    >
                      {questions.length - 1 === currentQuestion
                        ? "Add a question manualy"
                        : "Next Question"}
                    </Button>
                  </Box>
                ) : (
                  <Box>
                    <Question
                      key={questions[currentQuestion].uuid}
                      number={questions[currentQuestion].id}
                      questions={questions}
                      setQuestions={setQuestions}
                      currentQuestion={currentQuestion}
                      setCurrentQuestion={setCurrentQuestion}
                      generatedQuestions={generatedQuestions}
                      setGeneratedQuestions={setGeneratedQuestions}
                      showFront={showFront}
                      setShowFront={setShowFront}
                      setTypePhoto={setTypePhoto}
                      setIsQuestionComplete={setIsQuestionComplete}
                      nextClickedAndNotComplete={nextClickedAndNotComplete}
                    />
                    <Box
                      sx={{
                        transform: showFront
                          ? "rotateY(0deg)"
                          : "rotateY(180deg)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {currentQuestion > 0 && (
                          <Button
                            variant="soft"
                            onClick={handlePreviousQuestion}
                            sx={{ mb: 1, mr: 1 }}
                          >
                            Previous
                          </Button>
                        )}
                        {questions.length - 1 !== currentQuestion && (
                          <Button
                            variant="solid"
                            startDecorator={
                              questions.length - 1 === currentQuestion ? (
                                <AddIcon />
                              ) : (
                                <></>
                              )
                            }
                            onClick={
                              questions.length - 1 === currentQuestion
                                ? addQuestion
                                : handleNextQuestion
                            }
                            sx={{ mb: 1 }}
                          >
                            {questions.length - 1 === currentQuestion
                              ? "Add a question"
                              : "Next Question"}
                          </Button>
                        )}
                        {questions.length - 1 === currentQuestion &&
                          isQuestionComplete && (
                            <Button
                              fullWidth={false}
                              variant="solid"
                              color="success"
                              startDecorator={<DoneIcon />}
                              onClick={handleDone}
                              sx={{
                                mb: 1,
                                mr: 1,
                                marginLeft: "5px",
                                backgroundImage:
                                  "linear-gradient(to right, #4FF9A3, #B8FF93)",
                                color: "#00A24B",
                              }}
                            >
                              Done
                            </Button>
                          )}
                        {questions.length - 1 === currentQuestion && error && (
                          <Typography color="danger">{error}</Typography>
                        )}
                      </Box>
                      {questions.length - 1 === currentQuestion && (
                        <Box>
                          <Button
                            fullWidth={false}
                            variant="solid"
                            color="success"
                            endDecorator={<KeyboardArrowRight />}
                            onClick={addQuestion}
                          >
                            Manualy Add a Question
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </CSSTransition>
            </div>
          </Sheet>
          {currentQuestion !== -1 && (
            <Box
              sx={{
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
                p: 3,
                boxShadow: "lg",
                flex: "none",
                maxHeight: "600px",
                minHeight: "573px",
                maxWidth: "330px",
                background:
                  "linear-gradient(to bottom, rgb(233, 249, 254), rgb(213, 234, 240))",
                border: "0.5px solid #B9B9B9",
                borderLeft: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={typePhoto === "radio" ? iPhoneRadio : iPhoneCheckbox}
                alt="Photo"
                style={{
                  height: "90%",
                  width: "90%",
                  objectFit: "contain",
                }}
              />
            </Box>
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Questionnaire;

import { Box, Typography } from "@mui/joy";
import React from "react";
import Questionnaire from "../Questionnaire/Questionnaire";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import AspectRatio from "@mui/joy/AspectRatio";
import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";
import heroCardImage from "../../photo/heroCard.png";
import PublishIcon from "@mui/icons-material/Publish";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import API from "../../services/axios";
import Documentation from "../Documentation/Documentation";

const Dashboard = ({
  open,
  setOpen,
  questionsList,
  setQuestionList,
  addSurvey,
}) => {
  const [edit, setEdit] = React.useState(null);
  const [openDocumentation, setOpenDocumentation] = React.useState(false);
  const [docSurveyId, setDocSurveyId] = React.useState(null);
  const [AIQuota, setAIQuota] = React.useState(0);

  const handleEdit = (e) => {
    setEdit(e.uuid);
    setOpen(true);
  };

  const handlePublish = (e) => {
    setDocSurveyId(e.uuid);
    setOpenDocumentation(true);
  };

  const deleteSurvey = async (uuid) => {
    await API.deleteSurvey({ uuid });
    getSurveys();
  };

  const getSurveys = async () => {
    let surveys = await API.getSurvey();
    setQuestionList(surveys.surveys);
  };

  const getAIQuota = async () => {
    let quota = await API.getAIQuota();
    setAIQuota(quota.quota);
  };

  React.useEffect(() => {
    getSurveys();
    getAIQuota();
  }, []);

  return (
    <Box
      sx={{
        display: questionsList.length === 0 ? "flex" : "unset",
        justifyContent: questionsList.length === 0 ? "center" : "unset",
        alignItems: questionsList.length === 0 ? "center" : "unset",
        flexDirection: questionsList.length === 0 ? "column" : "unset",
        height: "90vh",
      }}
    >
      {questionsList.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "auto",
            textAlign: "center",
          }}
        >
          <Typography level="h1">No Projects Yet</Typography>
          <Typography
            level="h5"
            style={{ color: "gray", marginTop: "2px", marginBottom: "10px" }}
          >
            Create your first project now.
          </Typography>
          <Button
            color="primary"
            startDecorator={<AddCircleIcon />}
            size="sm"
            onClick={addSurvey}
            disabled={questionsList.length > 4}
            style={{ marginTop: "10px" }}
          >
            New Project
          </Button>
        </Box>
      )}
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {/* ici on map sur tout les questionnaires, en faire un component */}
        {questionsList.map((e) => (
          <Card
            key={e.uuid}
            variant="outlined"
            sx={{ width: 320, mr: 2, mb: 2 }}
          >
            <CardOverflow>
              <AspectRatio ratio="2">
                <img src={heroCardImage} alt="" />
              </AspectRatio>
            </CardOverflow>
            <CardContent sx={{ display: "flex", flexDirection: "column" }}>
              <Typography level="title-md">{e.title}</Typography>
              <Typography level="body-sm" sx={{ marginBottom: 1.5 }}>
                {e.description}
              </Typography>

              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  startDecorator={<PublishIcon />}
                  variant="solid"
                  size="md"
                  color="primary"
                  aria-label="Publish Onboarding"
                  sx={{ fontWeight: 600 }}
                  onClick={() => handlePublish(e)}
                >
                  Publish
                </Button>
                <Button
                  variant="soft"
                  size="md"
                  color="primary"
                  aria-label="Edit Onboarding"
                  sx={{ fontWeight: 600 }}
                  onClick={() => handleEdit(e)}
                >
                  Edit
                </Button>
                <Button
                  variant="soft"
                  size="md"
                  color="danger"
                  aria-label="Delete Onboarding"
                  sx={{ fontWeight: 600 }}
                  onClick={() => deleteSurvey(e.uuid)}
                >
                  Delete
                </Button>
              </Box>
            </CardContent>

            <CardOverflow variant="soft" sx={{ bgcolor: "background.level1" }}>
              <Divider inset="context" />
            </CardOverflow>
          </Card>
        ))}
      </Box>

      {open && (
        <Questionnaire
          open={open}
          setOpen={setOpen}
          questionsList={questionsList}
          setQuestionList={setQuestionList}
          setEdit={setEdit}
          surveyUuid={edit}
          AIQuota={AIQuota}
        />
      )}

      {openDocumentation && (
        <Documentation
          setOpenDocumentation={setOpenDocumentation}
          openDocumentation={openDocumentation}
          docSurveyId={docSurveyId}
          setDocSurveyId={setDocSurveyId}
        />
      )}
    </Box>
  );
};

export default Dashboard;

/*
{questionsList.length === 0 && (
          <Card variant="outlined" sx={{ width: 320, mr: 2, mb: 2 }}>
            <CardOverflow>
              <AspectRatio ratio="2">
                <img src={heroCardImage} alt="" />
              </AspectRatio>
            </CardOverflow>
            <CardContent sx={{ display: "flex", flexDirection: "column" }}>
              <Typography level="title-md">No Projects Yet</Typography>
              <Typography level="body-sm" sx={{ marginBottom: 1.5 }}>
                Bienvenue dans l'univers de notre librairie, une solution
                innovante conçue pour simplifier l'intégration de vos
                questionnaires directement sur votre site web. Grâce à notre
                technologie avancée, vous pouvez désormais importer facilement
                les questionnaires que vous avez préalablement créés sur notre
                plateforme, offrant une expérience utilisateur fluide et
                transparente.
              </Typography>

              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  startDecorator={<PublishIcon />}
                  variant="solid"
                  size="md"
                  color="primary"
                  aria-label="Publish Onboarding"
                  sx={{ fontWeight: 600 }}
                  onClick={() => setOpen(true)}
                >
                  Create a Survey
                </Button>
              </Box>
            </CardContent>

            <CardOverflow variant="soft" sx={{ bgcolor: "background.level1" }}>
              <Divider inset="context" />
            </CardOverflow>
          </Card>
        )}
*/

import React, { useState } from "react";
import AUTH from "../../services/auth";
import API from "../../services/axios";
import logo from "../../photo/loginHero.png";
import { Box, FormControl, FormLabel, Input, Typography, Button } from "@mui/joy";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Register = ({ setLogin, setIsConnected }) => {
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [secondPassword, setSecondPassword] = useState("");
  const [error, setError] = useState("");

  const handleCompanyChange = (e) => {
    const newValue = e.target.value;
    setCompany(newValue);
  };

  const handleEmailChange = (e) => {
    const newValue = e.target.value;
    setEmail(newValue);
  };

  const handlePasswordChange = (e) => {
    const newValue = e.target.value;
    setPassword(newValue);
  };

  const handleSecondPasswordChange = (e) => {
    const newValue = e.target.value;
    setSecondPassword(newValue);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      if (company === "" || email === "" || password === "" || secondPassword === "") {
        setError("You have to fill all the inputs.");
        return;
      }

      if (!emailRegex.test(email)) {
        setError("Bad Email Format.");
        return;
      }

      if (password !== secondPassword) {
        setError("Incorrect Password.");
        return;
      }

      setError(null);

      const user = await AUTH.register(email, password);
      localStorage.setItem("dashboardUser", JSON.stringify(user));
      await API.createUser();
      await API.createCompany({ name: company });
      setIsConnected(true);
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        setError("Bad Email Format.");
        return;
      } else {
        console.error(error);
        return;
      }
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", height: "100%", width: "100%" }}>
      <Box sx={{ height: "100vh", width: "100%", bgcolor: "#2D3136", flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <img src={logo} alt="vespucciLogo" style={{ maxWidth: "70%", maxHeight: "70%" }} />
      </Box>

      <Box
        sx={{
          height: "100%",
          width: "100%",
          borderRadius: "10px",
          p: 2,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <FormControl sx={{ mb: 2, width: "60%" }}>
          <FormLabel>Company</FormLabel>
          <Input placeholder="Type your company here" value={company} onChange={handleCompanyChange} />
          {/*<FormHelperText>This is a helper text.</FormHelperText>*/}
        </FormControl>

        <FormControl sx={{ mb: 2, width: "60%" }}>
          <FormLabel>Email</FormLabel>
          <Input placeholder="Email" value={email} onChange={handleEmailChange} />
          {/*<FormHelperText>This is a helper text.</FormHelperText>*/}
        </FormControl>

        <FormControl sx={{ mb: 2, width: "60%" }}>
          <FormLabel>Password</FormLabel>
          <Input placeholder="Password" type="password" value={password} onChange={handlePasswordChange} />
          {/*<FormHelperText>This is a helper text.</FormHelperText>*/}
        </FormControl>

        <FormControl sx={{ mb: 2, width: "60%" }}>
          <FormLabel>Confirm Password</FormLabel>
          <Input placeholder="Confirm your password" type="password" value={secondPassword} onChange={handleSecondPasswordChange} />
          {/*<FormHelperText>This is a helper text.</FormHelperText>*/}
        </FormControl>

        <Button
          fullWidth={true}
          onClick={handleRegister}
          sx={{
            marginTop: "20px",
            backgroundImage: "linear-gradient(to right, #658BFD, #25BBE4)",
            color: "#2E4896",
            width: "150px",
            margin: "auto",
            marginBottom: "32px",
          }}
        >
          Create
        </Button>

        {error && <Typography color="danger">{error}</Typography>}

        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
          <Typography>Already have an account ?</Typography>
          <Button
            sx={{
              ml: 2,
              backgroundColor: "transparent",
              boxShadow: "none",
              textTransform: "none",
              color: "#2E4896",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            }}
            onClick={() => setLogin(true)}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;

import React, { useState, useEffect } from "react";
import "./css/flip-transition.css";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Remove";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import {
  Select,
  Option,
  Box,
  Typography,
  Divider,
  Button,
  Input,
  FormHelperText,
} from "@mui/joy";

const emojiList = [
  { emoji: "⚡️", text: "Action" },
  { emoji: "🧷", text: "Attachment" },
  { emoji: "🚗", text: "Drive" },
  { emoji: "💎", text: "Diamond" },
  { emoji: "🧬", text: "DNA" },
  { emoji: "🥤", text: "Drink" },
  { emoji: "🍗", text: "Food" },
  { emoji: "👋", text: "Hi" },
  { emoji: "🔗", text: "Link" },
  { emoji: "📍", text: "Locate" },
  { emoji: "🔒", text: "Lock" },
  { emoji: "🍀", text: "Luck" },
  { emoji: "🎧", text: "Music" },
  { emoji: "🌙", text: "Nigth" },
  { emoji: "📞", text: "Phone" },
  { emoji: "📌", text: "Pin" },
  { emoji: "✈️", text: "Plane" },
  { emoji: "☔️", text: "Rain" },
  { emoji: "🔍", text: "Look" },
  { emoji: "🛌", text: "Sleep" },
  { emoji: "⭐️", text: "Star" },
  { emoji: "✋", text: "Stop" },
  { emoji: "🏷️", text: "Tag" },
  { emoji: "🔧", text: "Tools" },
  { emoji: "🗑️", text: "Trash" },
  { emoji: "⛱️", text: "Vacation" },
  { emoji: "🏆", text: "Win" },
  { emoji: "🚧", text: "Work" },
];

const Question = ({
  number,
  questions,
  setQuestions,
  currentQuestion,
  setCurrentQuestion,
  generatedQuestions,
  setGeneratedQuestions,
  showFront,
  setShowFront,
  setTypePhoto,
  setIsQuestionComplete,
  nextClickedAndNotComplete,
}) => {
  const indexQuestion = questions.findIndex((e) => e.id === number);
  const [qstTitle, setQstTitle] = useState(
    questions[indexQuestion].title ? questions[indexQuestion].title : ""
  );
  const [qstSubTitle, setQstSubTitle] = useState(
    questions[indexQuestion].subtitle ? questions[indexQuestion].subtitle : ""
  );
  const [type, setType] = useState(
    questions[indexQuestion].type ? questions[indexQuestion].type : ""
  );
  const [choices, setChoices] = useState(
    questions[indexQuestion].choices ? questions[indexQuestion].choices : []
  );
  const [emoji, setEmoji] = useState(
    questions[indexQuestion].emoji ? questions[indexQuestion].emoji : null
  );
  const [newChoice, setNewChoice] = useState("");

  const handleSave = () => {
    const newQuestions = [...questions];
    let index = newQuestions.findIndex((e) => e.id === number);
    newQuestions[index] = {
      ...newQuestions[index],
      title: qstTitle,
      subtitle: qstSubTitle,
      emoji,
      type,
      choices,
    };
    setQuestions(newQuestions);

    if (
      qstTitle === "" ||
      type === "" ||
      type === null ||
      ((type === "radio" || type === "checkbox") && choices.length === 0)
    ) {
      setIsQuestionComplete(false);
    } else {
      setIsQuestionComplete(true);
    }
  };

  useEffect(() => {
    handleSave();
  }, [qstTitle, qstSubTitle, type, choices, emoji]);

  const ajouterChoix = () => {
    if (newChoice.trim() !== "") {
      setChoices([...choices, newChoice]);
      setNewChoice("");
    }
  };

  const modifierChoix = (index, newValue) => {
    const newChoices = [...choices];
    newChoices[index] = newValue;
    setChoices(newChoices);
  };

  const supprimerChoix = (index) => {
    const newChoices = [...choices];
    newChoices.splice(index, 1);
    setChoices(newChoices);
  };

  const handleQstTitleChange = (e) => {
    const newValue = e.target.value;
    setQstTitle(newValue);
  };

  const handleQstSubTitleChange = (e) => {
    const newValue = e.target.value;
    setQstSubTitle(newValue);
  };

  const handleTypeChange = (e, newValue) => {
    setType(newValue);
    if (newValue) setTypePhoto(newValue);
  };

  const handleEmojiChange = (e, newValue) => {
    setEmoji(newValue);
  };

  const handleDelete = () => {
    const newQuestions = [...questions];
    let index = newQuestions.findIndex((e) => e.id === number);
    if (index !== -1) {
      newQuestions.splice(index, 1);
      for (let i = index; i < newQuestions.length; i++) {
        newQuestions[i].id = i + 1;
      }
      setCurrentQuestion(currentQuestion - 1);
      setQuestions(newQuestions);
    }
  };

  const handleRegenerate = () => {
    setShowFront(!showFront);
    const newGeneratedQuestions = [...generatedQuestions];
    let index = newGeneratedQuestions.findIndex((e) => !e.passed);

    if (index > -1) {
      setGeneratedQuestions(updateQuestion(index, newGeneratedQuestions));
    } else {
      // Si tout a déjà été généré
      const updatedGeneratedQuestions = generatedQuestions.map((question) => ({
        ...question,
        passed: questions.some((q) => q.uuid === question.uuid),
      }));

      index = updatedGeneratedQuestions.findIndex((e) => !e.passed);
      setGeneratedQuestions(updateQuestion(index, updatedGeneratedQuestions));
    }
  };

  const updateQuestion = (index, questionsArray) => {
    if (index !== -1) {
      const updatedQuestion = {
        ...questionsArray[index],
        passed: true,
        id: number,
      };

      const newQuestions = [...questions];
      newQuestions[number - 1] = updatedQuestion;
      setQuestions(newQuestions);
      questionsArray[index] = updatedQuestion;
    }

    return questionsArray;
  };

  return (
    <Box
      sx={{
        transformStyle: "preserve-3d",
        transform: showFront ? "rotateY(0deg)" : "rotateY(180deg)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h3" style={{ paddingBottom: "15px" }}>
          Question {number}{" "}
        </Typography>
        <Box style={{ paddingBottom: "15px" }}>
          <Button variant="outlined" color="danger" onClick={handleDelete}>
            <DeleteIcon />
          </Button>
          <Button
            variant="outlined"
            color="neutral"
            onClick={handleRegenerate}
            sx={{ ml: 1 }}
          >
            <CompareArrowsIcon />
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: "400px", overflow: "auto" }}>
        <Box sx={{ mb: 1.5 }}>
          <Typography style={{ color: "gray" }}>
            The question you want to ask{" "}
          </Typography>
          <Input
            placeholder="Title"
            required
            onChange={handleQstTitleChange}
            value={qstTitle}
            sx={{
              border: !nextClickedAndNotComplete
                ? ""
                : nextClickedAndNotComplete && qstTitle === ""
                ? "2px solid red"
                : "2px solid green",
              ...(nextClickedAndNotComplete && {
                "--Input-focusedHighlight": "transparent",
              }),
            }}
          />
        </Box>

        <Box sx={{ mb: 1.5 }}>
          <Typography style={{ color: "gray" }}>
            How do you define your question with an emoji ?{" "}
          </Typography>
          <Select
            placeholder="Choose an emoji"
            onChange={handleEmojiChange}
            value={emoji}
          >
            {emojiList.map((e) => (
              <Option key={e.emoji} value={e.emoji}>
                {e.emoji} {e.text}
              </Option>
            ))}
          </Select>
        </Box>

        {/*<Box sx={{ mb: 1.5 }}>
          <Typography>Subtitle of your question : (Optional)</Typography>
          <Input
            placeholder="Optional"
            onChange={handleQstSubTitleChange}
            value={qstSubTitle}
          />
        </Box>*/}

        <Box sx={{ mb: 1.5 }}>
          <Box sx={{ mb: 1 }}>
            <Typography style={{ color: "gray" }}>
              How do you want your users to answer ?
            </Typography>
            <Select
              placeholder="Choose a question type"
              onChange={handleTypeChange}
              value={type}
              sx={{
                border: !nextClickedAndNotComplete
                  ? ""
                  : nextClickedAndNotComplete && (type === "" || type === null)
                  ? "2px solid red"
                  : "2px solid green",
                ...(nextClickedAndNotComplete && {
                  "--Select-focusedHighlight": "transparent",
                }),
              }}
            >
              <Option value="checkbox">🎛️ With multiples answers</Option>
              <Option value="radio">✔️ With only one answer </Option>
              <Option value="input">
                🖋️ With an open field (Not Recommended)
              </Option>
            </Select>
            <FormHelperText id="select-field-demo-helper">
              This will determine the layout presented to your user (can be a
              checkbox, a radio button or a textfield).{" "}
            </FormHelperText>
          </Box>

          {(type === "checkbox" || type === "radio") && (
            <Box>
              <Typography style={{ color: "gray" }}>Choices </Typography>
              <Box sx={{ mb: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 0.6 }}>
                  <Input
                    required
                    placeholder="Add a choice here"
                    value={newChoice}
                    onChange={(e) => setNewChoice(e.target.value)}
                    sx={{
                      width: "100%",
                      mr: 1,
                      border: !nextClickedAndNotComplete
                        ? ""
                        : nextClickedAndNotComplete && choices.length === 0
                        ? "2px solid red"
                        : "",
                      ...(nextClickedAndNotComplete && {
                        "--Input-focusedHighlight": "transparent",
                      }),
                    }}
                  />
                  <Button
                    aria-label="Add"
                    variant="outlined"
                    color="neutral"
                    onClick={ajouterChoix}
                  >
                    <AddIcon />
                  </Button>
                </Box>
                {choices.map((choixItem, index) => (
                  <Box
                    key={index}
                    sx={{ mb: 0.6, display: "flex", alignItems: "center" }}
                  >
                    <Input
                      required
                      sx={{ width: "100%", mr: 1 }}
                      value={choixItem}
                      onChange={(e) => modifierChoix(index, e.target.value)}
                    />
                    <Button
                      aria-label="Delete"
                      variant="outlined"
                      color="neutral"
                      onClick={() => supprimerChoix(index)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Divider sx={{ mb: 2, mt: 2 }} />
    </Box>
  );
};

export default Question;

import React, { useState } from "react";
import { Box, Typography, Modal, Sheet, Button } from "@mui/joy";

const Documentation = ({
  setOpenDocumentation,
  openDocumentation,
  docSurveyId,
  setDocSurveyId,
}) => {
  const [page, setPage] = useState(0);
  const [language, setLanguage] = useState(null); // for iOs or React

  const handleNext = () => {
    setPage(page + 1);
    if (page === 2) setOpenDocumentation(false);
  };

  const handlePrevious = () => {
    if (page > 0) setPage(page - 1);
  };

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={openDocumentation}
      onClose={() => {
        setOpenDocumentation(false);
        setDocSurveyId(null);
      }}
      sx={{
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            flex: "1",
            width: "900px",
            maxHeight: "600px",
            borderRadius: "10px",
            boxShadow: "lg",
            overflow: "auto",
            border: "0.5px solid #B9B9B9",
            bgcolor: "#2D3136",

          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              id="stickyBox"
              sx={{
                p: 3,
                mb: 2,
                bgcolor: "#121217",
                position: "sticky",
                top: 0,
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {(() => {
                  const elements = [];
                  for (let i = 0; i < 3; i++) {
                    elements.push(
                      <Box key={i} sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          sx={{
                            borderRadius: "50%",
                            width: "35px",
                            height: "35px",
                            fontWeight: "bold", 
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color:
                            i <= page
                              ? "#F1C47F"
                              : "gray",                         
                            background:
                              i <= page
                                ? "linear-gradient(to bottom, #FFFFBC, #EFD2B8)"
                                : "#D7D9E0",
                          }}
                        >
                          <Typography color="white">{i}</Typography>
                        </Box>
                        {i < 2 && (
                          <Box
                            sx={{
                              height: "4px",
                              width: "150px",
                              background:
                                i < page
                                  ? "linear-gradient(to bottom, #FFFFBC, #EFD2B8)"
                                  : "#D7D9E0",
                            }}
                          ></Box>
                        )}
                      </Box>
                    );
                  }
                  return elements;
                })()}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {(() => {
                  const elements = [];
                  for (let i = 0; i < 3; i++) {
                    elements.push(
                     <Box key={i + 50} sx={{ mr: i < 2 ? "138px" : "0px" }}>
                        <Typography  sx={{color: "white"}} >{`Step ${i}`}</Typography>
                      </Box>
                    );
                  }
                  return elements;
                })()}
              </Box>
            </Box>

            <Box
              sx={{
                height: "100%",
                overflow: "auto",
                pl: 5,
                pr: 5,
                pb: 2,
                pt: 2,
              }}
            >
              {page === 0 && (
                <Box sx={{ mb: 3 }}>
                  <Typography level="h1" sx={{ mb: 1,  color: "white"  }}>
                    How to deploy Vespucci Hello ?
                  </Typography>
                  <Typography  sx={{ color: "gray" }}  >
                    Bienvenue dans l'univers de notre librairie, une solution
                    innovante conçue pour simplifier l'intégration de vos
                    questionnaires directement sur votre site web. Grâce à notre
                    technologie avancée, vous pouvez désormais importer
                    facilement les questionnaires que vous avez préalablement
                    créés sur notre plateforme, offrant une expérience
                    utilisateur fluide et transparente.
                  </Typography>
                  <br />
                  <Typography sx={{ color: "gray" }}>
                    Notre librairie offre une intégration transparente avec les
                    applications React, permettant à votre site de tirer
                    pleinement parti de la puissance des questionnaires que vous
                    avez conçus. Suivez ces étapes simples pour incorporer sans
                    effort vos questionnaires dans votre application React et
                    offrir une expérience interactive à vos utilisateurs.
                  </Typography>
                </Box>
              )}

              {/*<Box sx={{ mb: 3 }}>
                <Typography level="h2">0. Prérequis</Typography>
                <Typography sx={{ mb: 2 }}>
                  Lorsque vous appuierais sur le bouton "Publish" :{" "}
                </Typography>
                <img
                  src={publish}
                  alt="Photo"
                  style={{
                    height: "300px%",
                    width: "300px",
                    objectFit: "contain",
                  }}
                />
                <Typography>
                  Un email vous sera envoyé sur votre boîte mail avec un
                  identifiant unique pour ce questionnaire. Gardez cette
                  identifiant car vous en aurez besoin par la suite.
                </Typography>
              </Box>*/}

              {page === 1 && (
                <Box sx={{ mb: 3 }}>
                  <Typography level="h2" sx={{ mb: 1, color: "white" }}>
                    1. Installation
                  </Typography>
                  <Typography sx={{ mb: 1, color: "gray" }}>
                  Use npm to install the library:
                  </Typography>
                  <Box
                    sx={{
                      p: 1,
                      pl: 2,
                      bgcolor: "#F1F2F7",
                      borderRadius: "10px",
                      mb: 2,
                    }}
                  >
                    <pre>
                      <code>$ npm install vespucciLibrary</code>
                    </pre>
                  </Box>
                  <Typography sx={{ mb: 1, color: "gray" }}>
                    Or if you want to install the library with yarn:
                  </Typography>
                  <Box
                    sx={{
                      p: 1,
                      pl: 2,
                      bgcolor: "#F1F2F7",
                      borderRadius: "10px",
                      mb: 2,
                    }}
                  >
                    <pre>
                      <code>$ yarn install vespucciLibrary</code>
                    </pre>
                  </Box>
                </Box>
              )}

              {page === 2 && (
                <Box sx={{ mb: 3 }}>
                  <Typography level="h2" sx={{ mb: 1,  color: "white"  }}>
                    2. Integration
                  </Typography>

                  {false && (
                    <Box sx={{ mb: 2 }}>
                      <Typography level="h3" sx={{  color: "gray"  }}>
                        {"a) Récupérer votre questionnaire"}
                      </Typography>
                      <Typography sx={{  color: "gray"  }}>
                        Pour pouvoir récupérer le questionnaire en question,
                        reprenez l'identifiant que vous avez reçu au point 0.
                      </Typography>
                      <Typography sx={{ mb: 1,  color: "gray"  }}>
                        Au sein de votre projet React, vous allez faire comme
                        ceci :
                      </Typography>
                      <Box
                        sx={{
                          p: 1,
                          pl: 2,
                          bgcolor: "#F1F2F7",
                          borderRadius: "10px",
                          mb: 2,
                        }}
                      >
                        <pre>
                          <code>{'import * as React from "react"'}</code>
                          <br />
                          <code>
                            {'import { getSurvey } from "vespucciLibrary"'}
                          </code>
                          <br />
                          <code>{""}</code>
                          <br />
                          <code>{`await getSurvey("${docSurveyId}")`}</code>
                        </pre>
                      </Box>
                    </Box>
                  )}

                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ mb: 2 }}>
                      <Typography sx={{ mb: 2 , color: "gray" }}>
                        Integrate Vespucci Hello into your React application by importing the getSurvey method and the VespucciSurvey component.
                      </Typography>
                      <Typography sx={{ mb: 2, color: "gray"  }}>
                      Use the getSurvey method to retrieve your
                      onboarding flow template, then pass it as a property
                      (survey) property to VespucciSurvey.{" "}
                      </Typography>
                      <Typography sx={{ mb: 2 ,  color: "gray" }}>
                        In addition, be sure to provide the setSurveyResponses
                        function in prop to capture responses to the
                        and update the report accordingly.
                        Here's an example:
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        p: 1,
                        pl: 2,
                        bgcolor: "#F1F2F7",
                        borderRadius: "10px",
                        mb: 2,
                        overflowY: 'auto'
                      }}
                    >
                      <pre>
                        <div>
                          <code style={{ color: "#A21D00" }}>{"import "}</code>
                          <code>{"React, { useState, useEffect } "}</code>
                          <code style={{ color: "#A21D00" }}>{"from "}</code>
                          <code>{'"'}</code>
                          <code style={{ color: "#002381" }}>{"react"}</code>
                          <code>{'";'}</code>
                          <br />

                          <code style={{ color: "#A21D00" }}>{"import "}</code>
                          <code>{"{ getSurvey } "}</code>
                          <code style={{ color: "#A21D00" }}>{"from "}</code>
                          <code>{'"'}</code>
                          <code style={{ color: "#002381" }}>
                            {"vespucciLibrary"}
                          </code>
                          <code>{'";'}</code>
                          <br />

                          <code style={{ color: "#A21D00" }}>{"import "}</code>
                          <code>{"VespucciSurvey "}</code>
                          <code style={{ color: "#A21D00" }}>{"from "}</code>
                          <code>{'"'}</code>
                          <code style={{ color: "#002381" }}>
                            {"vespucciLibrary/VespucciSurvey"}
                          </code>
                          <code>{'";'}</code>
                          <br />
                        </div>

                        <code>{""}</code>
                        <br />

                        <div>
                          <code style={{ color: "#A21D00" }}>{"const "}</code>
                          <code>{"App "}</code>
                          <code style={{ color: "#0089C4" }}>{"= "}</code>
                          <code>{"() "}</code>
                          <code style={{ color: "#006895" }}>{"=> "}</code>
                          <code>{"{"}</code>
                          <br />

                          <code style={{ color: "#A21D00" }}>
                            {"   const "}
                          </code>
                          <code>{"[survey, setSurvey] "}</code>
                          <code style={{ color: "#0089C4" }}>{"= "}</code>
                          <code style={{ color: "#61496C" }}>{"useState"}</code>
                          <code>{"(null);"}</code>
                          <br />
                          <code style={{ color: "#A21D00" }}>
                            {"   const "}
                          </code>
                          <code>
                            {"[surveyResponses, setSurveyResponses] "}
                          </code>
                          <code style={{ color: "#0089C4" }}>{"= "}</code>
                          <code style={{ color: "#61496C" }}>{"useState"}</code>
                          <code>{"(null);"}</code>
                          <br />
                        </div>

                        <code>{""}</code>
                        <br />

                        <div>
                          <code style={{ color: "#61496C" }}>
                            {"   useEffect"}
                          </code>
                          <code>{"(() "}</code>
                          <code style={{ color: "#006895" }}>{"=> "}</code>
                          <code>{"{"}</code>
                          <br />

                          <code style={{ color: "#A21D00" }}>
                            {"      const "}
                          </code>
                          <code>{"getSurveyFromVespucci "}</code>
                          <code style={{ color: "#0089C4" }}>{"= "}</code>
                          <code style={{ color: "#A21D00" }}>{"async "}</code>
                          <code>{"() "}</code>
                          <code style={{ color: "#006895" }}>{"=> "}</code>
                          <code>{"{"}</code>
                          <br />

                          <code style={{ color: "#A21D00" }}>
                            {`         const `}
                          </code>
                          <code>{"surveyObject "}</code>
                          <code style={{ color: "#0089C4" }}>{"= "}</code>
                          <code style={{ color: "#A21D00" }}>{"await "}</code>
                          <code style={{ color: "#61496C" }}>
                            {"getSurvey"}
                          </code>
                          <code>{'("'}</code>
                          <code style={{ color: "green" }}>
                            {`${docSurveyId}`}
                          </code>
                          <code>{'");  '}</code>
                          <br />

                          <code style={{ color: "#61496C" }}>
                            {"         setSurvey"}
                          </code>
                          <code>{"(surveyObject);"}</code>
                          <br />

                          <code>{"      };"}</code>
                          <br />

                          <code>{""}</code>
                          <br />

                          <code style={{ color: "#61496C" }}>
                            {"      getSurveyFromVespucci"}
                          </code>
                          <code>{"();"}</code>
                          <br />

                          <code>{"   }, []);"}</code>
                        </div>
                        <br />

                        <code>{""}</code>
                        <br />

                        <div>
                          <code style={{ color: "#A21D00" }}>
                            {"   return"}
                          </code>
                          <code>{" ("}</code>
                          <br />

                          <code>{"      ..."}</code>
                          <br />

                          <code>{"      <"}</code>
                          <code style={{ color: "green" }}>
                            {"VespucciSurvey "}
                          </code>
                          <code style={{ color: "#A21D00" }}>{"survey"}</code>
                          <code style={{ color: "#0089C4" }}>{"="}</code>
                          <code>{"{survey} "}</code>
                          <code style={{ color: "#A21D00" }}>
                            {"setSurveyResponses"}
                          </code>
                          <code style={{ color: "#0089C4" }}>{"="}</code>
                          <code>{"{setSurveyResponses} "}</code>
                          <code>{"/>"}</code>
                          <br />

                          <code>{"      ..."}</code>
                          <br />

                          <code>{"   );"}</code>
                          <br />

                          <code>{"};"}</code>
                          <br />
                        </div>

                        <code>{""}</code>
                        <br />

                        <div>
                          <code style={{ color: "#A21D00" }}>
                            {"export default "}
                          </code>
                          <code>{"App;"}</code>
                          <br />
                        </div>
                      </pre>
                    </Box>
                    <Typography sx={{ mb: 2 ,  color: "gray" }}>
                      This configuration allows you to seamlessly integrate
                    your Vespucci Hello onboarding into your React
                    application, while facilitating response management with the setSurveyResponses function.
                    </Typography>
                  </Box>
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Box sx={{ display: "flex" }}>
                  {page > 0 && (
                    <Button  onClick={handlePrevious} sx={{ mr: 1, backgroundColor: 'transparent', 
                      boxShadow: 'none',  
                      textTransform: 'none', 
                      color: 'gray',  
                      '&:hover': {
                          backgroundColor: 'transparent', 
                          textDecoration: 'underline',},  
                         }}>
                      Previous
                    </Button>
                  )}
                  <Button onClick={handleNext}>
                    {page < 2 ? "Next" : "Done"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Sheet>
      </Box>
    </Modal>
  );
};

export default Documentation;

import * as React from "react";
import Dashboard from "../Dashboard/Dashboard";
import Header from "../Layout/Header.jsx";
import FirstSidebar from "../Layout/FirstSidebar.jsx";
import SecondSidebar from "../Layout/SecondSidebar.jsx";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { CssVarsProvider } from "@mui/joy/styles";
import Login from "../Login/Login";
import Register from "../Login/Register";

const App = () => {
  const [isConnected, setIsConnected] = React.useState(
    localStorage.getItem("dashboardUser") ? true : false
  );
  const [login, setLogin] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [questionsList, setQuestionList] = React.useState([]);

  const addSurvey = () => {
    if (questionsList.length < 5) setOpen(true);
  };

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      {!isConnected ? (
        <Box
          sx={{
            minHeight: "100vh",
          }}
        >
          {login ? (
            <Login setLogin={setLogin} setIsConnected={setIsConnected} />
          ) : (
            <Register setLogin={setLogin} setIsConnected={setIsConnected} />
          )}
        </Box>
      ) : (
        <Box sx={{ display: "flex", minHeight: "100vh" }}>
          <Header />
          <FirstSidebar setIsConnected={setIsConnected} />
          <SecondSidebar />
          <Box
            component="main"
            className="MainContent"
            sx={{
              px: {
                xs: 2,
                md: 6,
              },
              pt: {
                xs: "calc(12px + var(--Header-height))",
                sm: "calc(12px + var(--Header-height))",
                md: 3,
              },
              pb: {
                xs: 2,
                sm: 2,
                md: 3,
              },
              flex: 1,
              display: "flex",
              flexDirection: "column",
              minWidth: 0,
              height: "100dvh",
              gap: 1,
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  my: 1,
                  gap: 1,
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "start", sm: "center" },
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <Typography level="h2"></Typography>
                <Button
                  color="primary"
                  startDecorator={<AddCircleIcon />}
                  size="sm"
                  onClick={addSurvey}
                  disabled={questionsList.length > 4}
                >
                  New Project
                </Button>
              </Box>
              <Dashboard
                open={open}
                setOpen={setOpen}
                questionsList={questionsList}
                setQuestionList={setQuestionList}
                addSurvey={addSurvey}
              />
            </Box>
          </Box>
        </Box>
      )}
    </CssVarsProvider>
  );
};

export default App;

import React, { useState } from "react";
import AUTH from "../../services/auth";
import logo from "../../photo/loginHero.png";
import { Box, FormControl, FormLabel, Input, Button, Typography } from "@mui/joy";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Login = ({ setLogin, setIsConnected }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleEmailChange = (e) => {
    const newValue = e.target.value;
    setEmail(newValue);
  };

  const handlePasswordChange = (e) => {
    const newValue = e.target.value;
    setPassword(newValue);
  };

  const handlelogin = async (e) => {
    e.preventDefault();
    try {
      if (email === "" || password === "") {
        setError("You have to fill all the inputs.");
        return;
      }

      if (!emailRegex.test(email)) {
        setError("Bad Email format.");
        return;
      }

      setError(null);

      const user = await AUTH.connect(email, password);
      localStorage.setItem("dashboardUser", JSON.stringify(user));
      setIsConnected(true);
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setError("Bad Email.");
        return;
      } else if (error.code === "auth/wrong-password") {
        setError("Bad Password.");
        return;
      } else if (error.code === "auth/invalid-email") {
        setError("Bad Email Format.");
        return;
      } else {
        console.error(error);
        return;
      }
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", height: "100%", width: "100%" }}>
      <Box sx={{ height: "100vh", width: "100%", bgcolor: "#2D3136", flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <img src={logo} alt="vespucciLogo" style={{ maxWidth: "70%", maxHeight: "70%" }} />
      </Box>

      <Box
        sx={{
          height: "100%",
          width: "100%",
          borderRadius: "10px",
          p: 2,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <FormControl sx={{ mb: 2, width: "60%" }}>
          <FormLabel>Email</FormLabel>
          <Input placeholder="Type your email here" value={email} onChange={handleEmailChange} />
          {/*<FormHelperText>This is a helper text.</FormHelperText>*/}
        </FormControl>

        <FormControl sx={{ mb: 2, width: "60%" }}>
          <FormLabel>Password</FormLabel>
          <Input placeholder="Type your password here" value={password} type="password" onChange={handlePasswordChange} />
          {/*<FormHelperText>This is a helper text.</FormHelperText>*/}
        </FormControl>

        <Button
          fullWidth={true}
          onClick={handlelogin}
          sx={{
            marginTop: "20px",
            backgroundImage: "linear-gradient(to right, #658BFD, #25BBE4)",
            color: "#2E4896",
            width: "150px",
            margin: "auto",
            marginBottom: "32px",
          }}
        >
          Login
        </Button>

        {error && <Typography color="danger">{error}</Typography>}

        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
          <Typography>You dont have an account ?</Typography>
          <Button
            sx={{
              ml: 2,
              backgroundColor: "transparent",
              boxShadow: "none",
              textTransform: "none",
              color: "#2E4896",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            }}
            onClick={() => setLogin(false)}
          >
            Register
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;

import * as React from 'react';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemButton from '@mui/joy/ListItemButton';
// icons
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import StarsIcon from '@mui/icons-material/Stars';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';

import { closeSidebar } from './utils.js';

export default function SecondSidebar() {
  return (
	<React.Fragment>
	  <Box
		className="SecondSidebar-overlay"
		sx={{
		  position: 'fixed',
		  zIndex: 98,
		  top: 0,
		  left: 0,
		  width: '100vw',
		  height: '100vh',
		  opacity: 'var(--SideNavigation-slideIn)',
		  backgroundColor: 'var(--joy-palette-background-backdrop)',
		  transition: 'opacity 0.4s',
		  transform: {
			xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
			lg: 'translateX(-100%)',
		  },
		}}
		onClick={() => closeSidebar()}
	  />
	  <Sheet
		className="SecondSidebar"
		color="neutral"
		sx={{
		  position: {
			xs: 'fixed',
			lg: 'sticky',
		  },
		  transform: {
			xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
			lg: 'none',
		  },
		  transition: 'transform 0.4s',
		  zIndex: 99,
		  height: '100dvh',
		  top: 0,
		  p: 2,
		  flexShrink: 0,
		  display: 'flex',
		  flexDirection: 'column',
		  gap: 2,
		  borderRight: '1px solid',
		  borderColor: 'divider',
		}}
	  >
		<List
		  size="sm"
		  sx={{
			'--ListItem-radius': '6px',
			'--List-gap': '6px',
		  }}
		>
		  <ListSubheader role="presentation" sx={{ fontWeight: 'lg' }}>
			Vespucci Hello
		  </ListSubheader>
		  <ListItem>
			<ListItemButton onClick={() => closeSidebar()}>
			  <ListItemDecorator>
				<BubbleChartIcon />
			  </ListItemDecorator>
			  <ListItemContent>Projects</ListItemContent>
			</ListItemButton>
		  </ListItem>
		  <ListItem>
			<ListItemButton onClick={() => closeSidebar()}>
			  <ListItemDecorator>
				<InsertChartIcon />
			  </ListItemDecorator>
			  <ListItemContent>Integrations</ListItemContent>
			</ListItemButton>
		  </ListItem>
		  <ListSubheader role="presentation" sx={{ fontWeight: 700, mt: 2 }}>
			Analytics
		  </ListSubheader>
		  <ListItem>
			<ListItemButton onClick={() => closeSidebar()}>
			  <ListItemDecorator>
				<BubbleChartIcon />
			  </ListItemDecorator>
			  <ListItemContent>Projects</ListItemContent>
			</ListItemButton>
		  </ListItem>
		  <ListItem>
			<ListItemButton onClick={() => closeSidebar()}>
			  <ListItemDecorator>
				<ElectricalServicesIcon />
			  </ListItemDecorator>
			  <ListItemContent>Segment</ListItemContent>
			</ListItemButton>
		  </ListItem>
		  <ListItem>
			<ListItemButton onClick={() => closeSidebar()}>
			  <ListItemDecorator>
				<ElectricalServicesIcon />
			  </ListItemDecorator>
			  <ListItemContent>Amplitude</ListItemContent>
			</ListItemButton>
		  </ListItem>
		</List>
	  </Sheet>
	</React.Fragment>
  );
}

import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
const baseUrl = "https://datapipe.vespuccianalytics.com";


const refreshToken = async () => { // à changer ici
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idToken = await auth.currentUser.getIdToken();
        resolve({
          headers: { Authorization: `Bearer ${idToken}` },
        });
      } else {
        resolve(null);
      }
    });
  });
};

const createUser = async () => {
  let config = await refreshToken();
  return await axios
    .get(baseUrl + `/dashboard/newUser`, config)
    .then((response) => response.data)
};

const createCompany = async (body) => {
  let config = await refreshToken();
  return await axios
    .post(baseUrl + `/dashboard/newCompany`, body, config)
    .then((response) => response.data)
};

const getSurvey = async () => {
  let config = await refreshToken();
  return await axios
    .get(baseUrl + `/dashboard/survey`, config)
    .then((response) => response.data)
};

const createSurvey = async (body) => {
  let config = await refreshToken();
  return await axios
    .post(baseUrl + `/dashboard/survey/create`, body, config)
    .then((response) => response.data)
};

const updateSurvey = async (body) => {
  let config = await refreshToken();
  return await axios
    .post(baseUrl + `/dashboard/survey/update`, body, config)
    .then((response) => response.data)
};

const deleteSurvey = async (body) => {
  let config = await refreshToken();
  return await axios
    .post(baseUrl + `/dashboard/survey/delete`, body, config)
    .then((response) => response.data)
};

const generateAIQuestions = async (body) => {
  return await axios
    .post("https://vespuccihelloai.azurewebsites.net/createQuestions", body)
    .then((response) => response.data)
};

const getAIQuota = async (body = {}) => {
  let config = await refreshToken();
  return await axios
    .post(baseUrl + `/dashboard/survey/getAIQuota`, body, config)
    .then((response) => response.data)
};

const setAIQuota = async (body = {}) => {
  let config = await refreshToken();
  return await axios
    .post(baseUrl + `/dashboard/survey/setAIQuota`, body, config)
    .then((response) => response.data)
};

const API = {
  createUser,
  createCompany,
  getSurvey,
  createSurvey,
  updateSurvey,
  deleteSurvey,
  generateAIQuestions,
  getAIQuota,
  setAIQuota
};

export default API;